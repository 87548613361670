<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    transition="dialog-transition"
    scrollable
    :persistent="password !== passwordOg"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        color="color3"
        text
        x-small
      >Password Protection</v-btn>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Password Protection</v-toolbar-title>
      </v-toolbar>
      <loading-bar :value="loading"></loading-bar>
      <v-card-text>
        <div class="subheading">To enable password protected registrations enter a password below and click save</div>
        <v-text-field
          label="Password"
          v-model="password"
          :type="hidePassword ? 'password' : 'text'"
          :disabled="loading"
          :append-icon="hidePassword ? 'far fa-eye' : 'far fa-eye-slash'"
          @click:append="() => (hidePassword = !hidePassword)"
          clearable
        ></v-text-field>
        <v-alert type="error" :value="error" transition="scale-transition">
          Oops! We seemed to have failed you... Please, try again.
        </v-alert>
        <v-alert type="success" :value="success" transition="scale-transition">
          Your password has been {{this.password ? 'set' : 'removed'}}!
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="color3 color3Text--text"
          :disabled="password === passwordOg"
          :loading="loading"
          @click.stop="setPass"
        >Save</v-btn>
        <v-btn color="color3Text color3--text" @click.stop="dialog = false">{{this.success ? 'OK' : 'Cancel'}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['tournament', 'liveUpdates'])
  },
  data () {
    return {
      dialog: false,
      password: null,
      passwordOg: null,
      loading: false,
      hidePassword: true,
      error: false,
      success: false
    }
  },
  methods: {
    init () {
      this.loading = true
      this.liveUpdates.invoke('GetPass', this.tournament.id)
        .then(r => {
          this.password = r
          this.passwordOg = r
        })
        .finally(() => { this.loading = false })
    },
    setPass () {
      this.loading = true
      this.liveUpdates.invoke('setPass', { id: this.tournament.id, p: this.password })
        .then(r => {
          if (r) {
            this.success = true
            this.passwordOg = this.password
          } else {
            this.error = true
          }
        })
        .finally(() => { this.loading = false })
    },
    reset () {
      this.success = false
      this.error = false
      this.hidePassword = true
    }
  },
  watch: {
    dialog: function (val) {
      if (val) {
        this.init()
      } else {
        this.reset()
      }
    },
    password: function (val) {
      this.success = false
      this.error = false
    }
  }
}
</script>

<style>

</style>
